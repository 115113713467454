export const BreadcrumbsTranslations = {
  'eng': {
    //Study start
    study: 'Study',
    users: 'Users',
    investmentstudy: 'Investment study',
    projectdefining: 'Project defining',
    newinvestmentstudy: 'New investment study',
    editstudy: 'Edit',
    systemsettings: 'System settings',
    newprojectdefining: 'New project defining',
    newfinanceanalysis: 'New finance analysis',
    newshortbalance: 'New short RDG & balance',
    newloan: 'New loan',
    newprojectrealization: 'New project realization',
    studyintro: 'Intro',
    studyinfo: 'Info',
    projectdescription: 'Project description',
    studyinitialinvestment: 'Initial investment and finance sources',
    qualificationsstructure: 'Employees structure',
    humanresourcesreport: 'Human resources report',
    studyproducts: 'Technological capacity and amount of production',
    studymarketing: 'Marketing activity costs',
    entrepreneurinfo: 'Entrepreneur info',
    estimatevalues: 'Estimate values',
    investmentbudget: 'Investment budget',
    investmentsources: 'Investment sources',
    loan: 'Loan',
    materialexpenses: 'Material expenses',
    serviceexpenses: 'Service expenses',
    otherexpenses: 'Other expenses',
    amortization: 'Amortization',
    expensescalculation: 'Expenses budget and price calculations',
    totalincome: 'Total income',
    totalexpense: 'Total expense',
    profitandloss: 'Profit and loss account',
    currentassetsprojection: 'Projection of needed current assets',
    financeflow: 'Finance flow',
    economyflow: 'Economy flow',
    balance: 'Balance',
    indicators: 'Indicators',
    dynamicprojectrating: 'Dynamic project rating',
    marketanalysis: 'Market analysis',
    procurementmarket: 'Procurement market',
    salesmarket: 'Sales market',
    marketanalysissummary: 'Market analysis summary and income estimation',
    locationanalysis: 'Location analysis',
    rdgandbalance: 'RDG & Balance',

    //Study end

    //project defining
    riskanalysis: 'Risk analysis',
    applicantimplementationcapacity: 'Provedbeni kapaciteti prijavitelja',
    projecttechnicalelements: 'Tehnički elementi projekta',
    projectcommercialsustainability: 'Komercijalna održivost projekta',

    graphs: 'Graphs',

    home: 'Home',
    company: 'Company',
    companies: 'Companies',
    user: 'Users',
    details: 'Details',
    activity: 'Activity',
    contacts: 'Contacts',
    branches: 'Branches',
    projects: 'Projects',
    newproduct: 'New Product',
    editproduct: 'Edit Product',
    newuser: 'New User',
    edituser: 'Edit User',
    newbranch: 'New Branch',
    editbranch: 'Edit Branch',
    newcompany: 'New Company',
    editcompany: 'Edit Company',
    analytics: 'Analytics',
    sessions: 'Sessions',
    ui: 'UI',
    info: 'Info',
    admin: 'Admin',
    general: 'General',
    lists: 'Lists',
    overview: 'Overview',
    conversions: 'Conversions',
    alerts: 'Alerts',
    settings: 'Settings',
    documents: 'Documents',
    courses: 'Courses',
    // Products start
    products: 'Products',
    materials: 'Materials',
    productsmaterials: 'Products and materials',
    list: 'List',
    // Products end
    // Elearning start
    elearning: 'E-learning',
    participants: 'Participants',
    virtualclassroom: 'Virtual Classroom',
    discussions: 'Discussions',
    // Elearning end
    // Lead start
    lead: 'Lead',
    opportunity: 'Opportunity',
    // Lead end
    // Mailbox start
    mailbox: 'Mailbox',
    inbox: 'Inbox',
    // Mailbox end
    // Calendar start
    calendar: 'Calendar',
    // Calendar end
    // Reports start
    reports: 'Reports',
    // Reports end
    departments: 'Departments',
    newdepartment: 'New department',
    editdepartment: 'Edit department',
    newcourse: 'New course',
    editcourse: 'Edit course',
    attributes: 'Attributes',
    newattribute: 'New attribute',
    editattribute: 'Edit attribute',
    attributesets: 'Attribute sets',
    newattributeset: 'New attribute set',
    editattributeset: 'Edit attribute set',
    productsservices: 'Products and services',
    newservice: 'New service',
    editservice: 'Edit service',
    materialsexternalservices: 'Materials and external services',
    newmaterial: 'New material',
    editmaterial: 'Edit material',
    externalservices: 'External services',
    newexternalservice: 'New external service',
    editexternalservice: 'Edit external service',
    newclass: 'New class',
    editclass: 'Edit class',
    classes: 'Classes',
    classdetails: 'Class details',
    discussiondetails: 'Discussion details',
    editdiscussion: 'Edit discussion',
    newdiscussion: 'New discussion',
    classrooms: 'Classrooms',
    newclassroom: 'New classroom',
    editclassroom: 'Edit classroom',
    warehouse: 'Warehouse',
    newwarehouse: 'New warehouse',
    editwarehouse: 'Edit warehouse',
    newproject: 'New project',
    editproject: 'Edit project',
    project: 'Project',
    task: 'Task',
    tasks: 'Tasks',
    newtask: 'New task',
    edittask: 'Edit task',
    timeexpenses: 'Time & Expenses',
    kanban: 'Kanban',
    humanresources: 'Human resources',
    attendance: 'Attendance',
    workstatus: 'Work status',
    absenceschedule: 'Absence schedule',
    healthandsafety: 'Health & Safety',
    bins: 'Bins',
    newbin: 'New bin',
    editbin: 'Edit bin',
    newasset: 'New asset',
    editasset: 'Edit asset',
    assets: 'Assets',
    report: 'Report',
    newcategory: 'New category',
    editcategory: 'New category',
    managecategories: 'Manage categories',
    exams: 'Exams',
    exam: 'Exam',
    examslist: 'Exam list',
    newexam: 'New exam',
    editexam: 'Edit exam',

    //<editor-fold desc="Reports">
    outgoinginvoicereport: 'Outgoing invoice report',
    inboundinvoicereport: 'Outgoing invoice report',
    contractreport: 'Contract report',
    inboundorderreport: 'Inbound order report',
    outgoingorderreport: 'Outgoing order report',
    projectgeneralreport: 'Project general report',
    projectloggedtimereport: 'Project logged time report',
    //</editor-fold>
    stock: 'Stock',
    pricelist: 'Price list',
    stockmovement: 'Stock movement',
    drafts: 'Drafts',
    mentorsusers: 'Mentors users',
    mentoring: 'Mentoring',
    //TODO change
    mentorsrequests: 'Requests to mentor',
    promotionrequests: 'Mentor requests',
    tools: 'Tools',
    recentfinanceanalysis: 'Recent business financial analysis',
    financeanalysis: 'Financial analysis',
    shortbalance: 'Balance & RDG',
    projectrealization: 'Project realization',
    shortdescription: "Project summary",
    longdescription: "Project description",
    purposeandjustification: "Purpose and justification",
    socioeconomicframework: "Socio-economic framework",
    socioeconomicmaincharacteristics: "Main characteristics of the socio-economic environment",
    socioeconomicprojectpurpose: "Defining the purpose of the project and its impact on the socio-economic environment",
    nationalframeworkscompliance: "Compliance with EU and national frameworks",
    institutionalandlegalframework: "Institutional and legal framework",
    applicantgeneralinformation: "Applicant general information",
    contactinfo: "Contact information",
    responsiblepersoninfo: "Responsible person information",
    financialcapacities: "Financial capacities",
    partners: "Partners",
    currentstatedescription: "Project identification/ Current state description",
    futuretrendsassessment: "Future trends assessment",
    indirectandnetworkeffects: "Indirect and network effects",
    endusers: "End users",
    targetgroups: "Target groups",
    otheroptionsanalysis: "Feasibility and options analysis/ Identification and analysis of other options",
    bestoptiondefining: "Defining and explaining the best option",
    projectresultsjustification: "Project results justification",
    demandanalysis: "Demand analysis",
    locationandaccessibility: "Technical analysis/ Location and accessibility",
    currentsituationdescription: "A brief description of the current situation",
    constructionconcept: "Construction and architectural concept",
    equipmentcosts: "Construction and/or equipment costs",
    infrastructurefunctions: "Areas and functions of the infrastructure",
    environmentalimpactassessment: "Environmental impact assessment",
    technicaldocumentation: "Permits and technical documentation",
    humanresourcesmanagement: "Human resources management",
    financesandaccounting: "Finances and accounting",
    propertymanagement: "Property management",
    logistics: "Logistics",
    marketing: "Marketing",
    purchases: "Purchases",
    sales: "Sales",
    strategicplanning: "Strategic planning",
    riskmanagement: "Risk management",
    otherprocesses: "Other processes",
    resultssustainability: "Sustainability of the results after the end of the project",
    financialanalysisassumptions: "Financial analysis assumptions",
    investmentamount: "Investment amount/ material and non-material costs",
    operatingincomeandexpenses: "Operating income and expenses",
    financialinvestmentreturn: "Financial return on investment",
    financialcapitalreturn: "Financial capital return",
    financialsustainability: "Financial sustainability",
    sourcesoffinancing: "Sources of financing",
    marketoeconomicconversion: "Economic analysis/ Conversion of market prices into economic prices",
    economiceffectsindicators: "Economic effects indicators",
    socialbenefitsquantification: "Social benefits quantification",
    otherindirecteffects: "Other indirect effects",
    estimatedcostsdiscounting: "Discounting of estimated costs and benefits",
    organisationalstructureproposal: "Methodology of project team establishment/ Organisational structure proposal",
    teamrolesdefinition: "Team roles definition",
    requiredqualifications: "Required qualifications",
    monitoringmethod: "Method of monitoring and evaluation of results",
    implementationcapacities: "Information on implementation capacities and partner selection",
    projectactivities: "Project activities",
    budgetactivities: "Budget activities",
    budgetsummary: "Budget summary",
    ineligibleprivatefunds: "Ineligible expenses - private funds",
    budgetsourcesoffinancing: "Budget summary/ Sources of funding",
    netincomeestimationmethod: "Estimated net income",
    genderequalityinfluence: "Horizontal principles/ promotion of gender equality",
    nondiscriminationprinciples: "Promotion of the principle of non-discrimination",
    infrastructureaccessibility: "Accessibility to infrastructure/ Public transport",
    communicationaccessibility: "Information and communication accessibility",
    reasonableadjustment: "Reasonable adjustment and universal design",
    publicservices: "Accessibility of other content and services open or intended for the public",
    additionalactivitieshandicap: "Additional activities",
    greenpublicprocurement: "Sustainable development/ Green public procurement",
    climateconfirmation: "Climate confirmation",
    nosignificantharmcontribution: "Contribution to the 'do no significant harm' principle",
    additionalactivitiesgreen: "Additional activities",
    euandun: "EU Charter and UN Convention",
    printmedia: "Information and visibility / Print media",
    electronicmediapage: "Electronic media",
    internet: "Internet",
    seminarsconferences: "Seminars, conferences, workshops, etc.",
    promotionalmaterial: "Promotional material",
    newcommunicationforms: "Proposed new/innovative forms of communication with the public",
    signorposter: "A sign/poster placed at the project location",
    informationboard: "Permanent/information board",
    visibilitymarks: "Visibility marks on project documentation",
    equipmentvisibilitymarks: "Visibility marks on equipment",
    nonrefundablefunds: "Non refundable funds"
  },
  'hrv': {
    //Study start
    study: 'Studija',
    users: 'Korisnici',
    investmentstudy: 'Investicijska studija',
    projectdefining: 'Definiranje projekta',
    newinvestentstudy: 'Nova investicijska studija',
    editstudy: 'Unos',
    systemsettings: 'Postavke sustava',
    newprojectdefining: 'Novo definiranje projekta',
    newfinanceanalysis: 'Nova financijska analiza',
    newshortbalance: 'Nova kratka bilanca i RDG',
    newloan: 'Novi obračun kredita',
    newprojectrealization: 'Nova realizacija projekta',
    studyintro: 'Uvod',
    studyinfo: 'Info',
    projectdescription: 'Opis projekta',
    studyinitialinvestment: 'Predračunska vrijednost ulaganja i izvori financiranja',
    qualificationsstructure: 'Struktura zaposlenika',
    humanresourcesreport: 'Izvještaj ljudskih resursa',
    studyproducts: 'Tehnološki kapacitet i obujam proizvodnje',
    economicfinancialanalysis: 'Ekonomsko financijska analiza',
    basicinstrumentsinvestment: 'Ulaganje u osnovna sredstva',
    studymarketing: 'Troškovi marketinških aktivnosti',
    entrepreneurinfo: 'Podaci o poduzetniku',
    estimatevalues: 'Estimate values',
    investmentbudget: 'Proračun troškova ulaganja',
    investmentsources: 'Plan izvora sredstava',
    loan: 'Kredit',
    materialexpenses: 'Materijalni troškovi',
    serviceexpenses: 'Troškovi usluga',
    otherexpenses: 'Ostali troškovi',
    expensescalculation: 'Proračun troškova i kalkulacija cijena',
    totalincome: 'Formiranje ukupnog prihoda',
    totalexpense: 'Formiranje ukupnog rashoda',
    profitandloss: 'Račun dobiti i gubitka',
    currentassetsprojection: 'Projekcija potrebnih obrtnih sredstava',
    financeflow: 'Financijski tok',
    economyflow: 'Ekonomski tok',
    balance: 'Bilanca',
    indicators: 'Indikatori',
    dynamicprojectrating: 'Dinamička ocjena projekta',
    marketanalysis: 'Analiza tržišta',
    procurementmarket: 'Tržište nabave',
    salesmarket: 'Tržište prodaje',
    marketanalysissummary: 'Sažetak analize tržište i procjena ostvarenja prihoda',
    locationanalysis: 'Analiza lokacije',
    rdgandbalance: 'RDG & Bilanca',

    //Study end

    //Project defining

    graphs: 'Grafički pokazatelji',

    materialsexternalservices: 'Materijali i vanjske usluge',
    newmaterial: 'Novi materijal',
    editmaterial: 'Uredi materijal',
    home: 'Početna',
    company: 'Tvrtka',
    companies: 'Tvrtke',
    user: 'Korisnik',
    details: 'Detalji',
    activity: 'Aktivnosti',
    contacts: 'Kontakti',
    branches: 'Poslovnice',
    projects: 'Projekti',
    newproduct: 'Novi Proizvod',
    editproduct: 'Uredi Proizvod',
    newuser: 'Novi Korisnik',
    edituser: 'Uredi Korisnika',
    newbranch: 'Nova Poslovnica',
    editbranch: 'Uredi Poslovnicu',
    newcompany: 'Nova Tvrtka',
    editcompany: 'Uredi Tvrtku',
    analytics: 'Analitika',
    sessions: 'Sesije',
    ui: 'UI',
    info: 'Info',
    admin: 'Administrator',
    general: 'Generalno',
    lists: 'Liste',
    overview: 'Pregled',
    conversions: 'Konverzije',
    alerts: 'Obavjesti',
    settings: 'Postavke',
    documents: 'Dokumenti',
    courses: 'Tečajevi',
    // Products start
    products: 'Proizvodi',
    materials: 'Materijali',
    productsmaterials: 'Proizvodi i materijali',
    list: 'Lista',
    // Products end
    // Elearning start
    elearning: 'E-učenje',
    participants: 'Sudionici',
    virtualclassroom: 'Virtualna učionica',
    discussions: 'Diskusije',
    // Elearning end
    // Lead start
    lead: 'Trag',
    opportunity: 'Prilika',
    // Lead end
    // Mailbox start
    mailbox: 'Pošta',
    inbox: 'Spremnik',
    // Mailbox end
    // Calendar start
    calendar: 'Kalendar',
    // Calendar end
    // Reports start
    reports: 'Izvještaji',
    // Reports end,
    departments: 'Odjeli',
    newdepartment: 'Novi odjel',
    editdepartment: 'Udredi odjel',
    newcourse: 'Novi tečaj',
    editcourse: 'Uredi tečaj',
    attributes: 'Atributi',
    newattribute: 'Novi atribut',
    editattribute: 'Uredi atribut',
    attributesets: 'Setovi atributa',
    newattributeset: 'Novi set atributa',
    editattributeset: 'Uredi set atributa',
    productsservices: 'Proizvodi i usluge',
    newservice: 'Nova usluga',
    editservice: 'Uredi uslugu',
    externalservices: 'Vanjske usluge',
    newexternalservice: 'Nova vanjska usluga',
    editexternalservice: 'Uredi vanjsku uslugu',
    newclass: 'Nova lekcija',
    editclass: 'Uredi lekciju',
    classes: 'Lekcije',
    classdetails: 'Detalji lekcije',
    discussiondetails: 'Detalji diskusije',
    editdiscussion: 'Uredi diskusiju',
    newdiscussion: 'Nova diskusija',
    classrooms: 'Učionice',
    newclassroom: 'Nova učionica',
    editclassroom: 'Uredi učionicu',
    warehouse: 'Skladište',
    newwarehouse: 'Novo skladište',
    editwarehouse: 'Uredi skladište',
    newproject: 'Novi projekt',
    editproject: 'Uredi projekt',
    project: 'Projekt',
    task: 'Zadatak',
    tasks: 'Zadatci',
    newtask: 'Novi zadatak',
    edittask: 'Uredi zadatak',
    timeexpenses: 'Vrijeme i troškovi',
    kanban: 'Kanban',
    humanresources: 'Ljudski resursi',
    attendance: 'Prisutnost',
    workstatus: 'Radni status',
    absenceschedule: 'Raspored odutnosti',
    healthandsafety: 'Zdravlje i sigurnost',
    bins: 'Police',
    newbin: 'Nova polica',
    editbin: 'Uredi policu',
    newasset: 'Nova imovina',
    editasset: 'Uredi imovinu',
    assets: 'Imovina',
    report: 'Report',
    newcategory: 'Nova kategorija',
    editcategory: 'Uredi kategoriju',
    managecategories: 'Upavljanje kategorijama',
    exams: 'Ispiti',
    exam: 'Ispit',
    examslist: 'Lista ispita',
    newexam: 'Novi ispit',
    editexam: 'Uredi ispit',

    //<editor-fold desc="Reports">
    projectgeneralreport: 'Projekti opći izvještaj',
    outgoinginvoicereport: 'Izlazni računi izvještaj',
    inboundinvoicereport: 'Ulazni računi izvještaj',
    contractreport: 'Ugovori izvještaj',
    inboundorderreport: 'Ulazne narudžbenice izvještaj',
    outgoingorderreport: 'Izlazna narudžbenice izvještaj',
    projectloggedtimereport: 'Projekti logirano vrijeme izvještaj',
    //</editor-fold>
    stock: 'Zaliha',
    pricelist: 'Cijenk',
    stockmovement: 'Kretanje zaliha',
    drafts: 'Drafts',
    mentorsusers: 'Mentorovi korisnici',
    mentoring: 'Menoriranje',
    mentorsrequests: 'Zahtjevi za mentoriranje',
    promotionrequests: 'Mentor zahtjevi',
    tools: 'Alati',
    recentfinanceanalysis: 'Financijska analiza dosadašnjeg poslovanja',
    financeanalysis: 'Financijska analiza',
    shortbalance: 'Bilanca & RDG',
    projectrealization: 'Realizacija projekta',
    shortdescription: 'Sažetak projekta',
    longdescription: 'Opis projekta',
    purposeAndJustification: 'Svrha i opravdanost',
    socioeconomicframework: 'Socio-ekonomski okvir',
    socioeconomicmaincharacteristics: 'Glavne karakteristike socio-ekonomskog okruženja',
    socioeconomicprojectpurpose: 'Definiranje svrhe projekta i njegov utjecaj na društveno-gospodarsko okruženje',
    nationalframeworkscompliance: 'Usuglašenost s EU i nacionalnim okvirima',
    institutionalandlegalframework: 'Institucionalni i pravni okvir',
    applicantgeneralinformation: 'Opći podaci o prijavitelju',
    contactinfo: 'Kontakt podatci',
    responsiblepersoninfo: 'Podatci o odgovornoj osobi',
    financialcapacities: 'Financijski kapaciteti',
    partners: 'Partneri',
    currentstatedescription: 'Identifikacija projekta/ Opis trenutačnog stanja',
    futuretrendsassessment: 'Procjena budućih trendova',
    indirectandnetworkeffects: 'Neizravni i mrežni učinci projekta',
    endusers: 'Krajnji korisnici',
    targetgroups: 'Ciljne skupine',
    otheroptionsanalysis: 'Analiza izvedivosti i opcija/ Identifikacija i analiza ostalih mogućnosti',
    bestoptiondefining: 'Definiranje i obrazloženje najbolje mogućnosti',
    projectresultsjustification: 'Obraloženje rezultata projekta',
    demandanalysis: 'Analiza potražnje',
    locationandaccessibility: 'Tehnička analiza/ Lokacija i pristupačnost',
    currentsituationdescription: 'Kratak opis postojećeg stanja',
    constructionconcept: 'Građevinsko-arhitektonski koncept',
    equipmentcosts: 'Troškovi izgradnje i/ili opreme',
    infrastructurefunctions: 'Površine i funkcije infrastrukture',
    environmentalimpactassessment: 'Procjena utjecaja na okoliš',
    technicaldocumentation: 'Dozvole i tehnička dokumentacija',
    humanresourcesmanagement: 'Upravljanje ljudskim potencijalima',
    financesandaccounting: 'Financije i računovodstvo',
    propertymanagement: 'Upravljanje imovinom',
    logistics: 'Logistika',
    purchases: 'Nabava',
    sales: 'Prodaja',
    strategicplanning: 'Strateško planiranje',
    riskmanagement: 'Analiza rizika',
    otherprocesses: 'Ostali procesi',
    resultssustainability: 'Održivost rezultata nakon završetka projekta',
    financialanalysisassumptions: 'Financijska analiza/ prepostavke financijske analize',
    investmentamount: 'Visina investicije/ materijalni i nematerijalni troškovi',
    operatingincomeandexpenses: 'Operativni prihodi i rashodi',
    financialinvestmentreturn: 'Financijski povrat investicije',
    financialcapitalreturn: 'Financijski povrat kapitala',
    financialsustainability: 'Financijska održivost',
    sourcesoffinancing: 'Izvori financiranja',
    markettoeconomicconversion: 'Ekonomska analiza/ Konverzija tržišnih u ekonomske cijene',
    economiceffectsindicators: 'Indikatori ekonomskih učinaka',
    socialbenefitsquantification: 'Kvantifikacija društvenih koristi',
    otherindirecteffects: 'Ostali neizravni učinci',
    estimatedcostsdiscounting: 'Diskontiranje procijenjenih troškova i koristi',
    organisationalstructureproposal: 'Metodologija uspostave projektnog tima/ Prijedlog organizacijske strukture',
    teamrolesdefinition: 'Definicija uloga u timu',
    requiredqualifications: 'Opis potrebnih kvalifikacija',
    monitoringmethod: 'Način praćenja i vrednovanja rezultata',
    implementationcapacities: 'Informacije o provedbenim kapacitetima i odabiru partnera',
    projectactivities: 'Aktivnosti projekta',
    budgetactivities: 'Aktivnosti proračuna',
    budgetsummary: 'Sažetak proračuna',
    ineligibleprivatefunds: 'Neprihvatljivi troškovi - privatna sredstva',
    budgetsourcesoffinancing: 'Izvori financiranja',
    netincomeestimationmethod: 'Proecijenjeni neto prihod',
    genderequalityinfluence: 'Horizontalna načela/ promicanje ravnopravnosti spolova',
    nondiscriminationprinciples: 'Promicanje načela nediskriminacije',
    infrastructureaccessibility: 'Pristupačnost infrastrukturi/ Javnom prijevozu',
    communicationaccessibility: 'Informacijsko-komunikacijska pristupačnost',
    reasonableadjustment: 'Razumna prilagodba i univerzalni dizajn',
    publicservices: 'Pristupačnost ostalih sadržaja i usluga otvorenih ili namijenjenih javnosti',
    additionalactivitieshandicap: 'Dodatne aktivnosti',
    greenpublicprocurement: 'Održivi razvoj/ Zelena javna nabava',
    climateconfirmation: 'Klimatsko potvrđivanje',
    nosignificantharmcontribution: 'Doprinos načelu „ne čini bitnu štetu“',
    additionalactivitiesgreen: 'Dodatne aktivnosti',
    euandun: 'EU povelja i UN konvencija',
    printmedia: 'Informiranje i vidljivsot/ Tiskani mediji',
    electronicmedia: 'Elektronički mediji',
    internet: 'Internet',
    seminarsconferences: 'Seminari, konferencije, radionice itd.',
    promotionalmaterial: 'Promotivni materijal',
    newcommunicationforms: 'Predloženi novi/inovativni oblici komunikacije s javnošću',
    signorposter: 'Znakovlje/plakat postavljen na lokaciji projekta',
    informationboard: 'Trajna/informacijska ploča',
    visibilitymarks: 'Oznake vidljivosti na projektnoj dokumentaciji',
    equipmentvisibilitymarks: 'Oznake vidljivosti na opremi',
    nonrefundablefunds: 'Nepovratna sredstva'


  }
};
