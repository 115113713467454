export const ProjectTranslations = {
  'eng': {
    shortDescription: 'Project summary',
    longDescription: 'Project description',
    purposeAndJustification: 'Purpose and justification',
    socioEconomicFramework: 'Socio-economic framework',
    socioEconomicMainCharacteristics: 'Main characteristics of the socio-economic environment',
    socioEconomicProjectPurpose: 'Defining the purpose of the project and its impact on the socio-economic environment',
    nationalFrameworksCompliance: 'Compliance with EU and national frameworks',
    institutionalAndLegalFramework: 'Institutional and legal framework',
    applicantGeneralInformation: 'Applicant general information',
    contactInfo: 'Contact information',
    responsiblePersonInfo: 'Responsible person information',
    financialCapacities: 'Financial capacities',
    partners: 'Partners',
    currentStateDescription: 'Project identification/ Current state description',
    futureTrendsAssessment: 'Future trends assessment',
    indirectAndNetworkEffects: 'Indirect and network effects',
    endUsers: 'End users',
    targetGroups: 'Target groups',
    otherOptionsAnalysis: 'Feasibility and options analysis/ Identification and analysis of other options',
    bestOptionDefining: 'Defining and explaining the best option',
    projectResultsJustification: 'Project results justification',
    demandAnalysis: 'Demand analysis',
    locationAndAccessibility: 'Technical analysis/ Location and accessibility',
    currentSituationDescription: 'A brief description of the current situation',
    constructionConcept: 'Construction and architectural concept',
    equipmentCosts: 'Construction and/or equipment costs',
    infrastructureFunctions: 'Areas and functions of the infrastructure',
    environmentalImpactAssessment: 'Environmental impact assessment',
    technicalDocumentation: 'Permits and technical documentation',
    humanResourcesManagement: 'Human resources management',
    financesAndAccounting: 'Finances and accounting',
    propertyManagement: 'Property management',
    logistics: 'Logistics',
    marketing: 'Marketing',
    purchases: 'Purchases',
    sales: 'Sales',
    strategicPlanning: 'Strategic planning',
    riskManagement: 'Risk management',
    otherProcesses: 'Other processes',
    resultsSustainability: 'Sustainability of the results after the end of the project',
    financialAnalysisAssumptions: 'Financial analysis assumptions',
    investmentAmount: 'Investment amount/ material and non-material costs',
    operatingIncomeAndExpenses: 'Operating income and expenses',
    financialInvestmentReturn: 'Financial return on investment',
    financialCapitalReturn: 'Financial capital return',
    financialSustainability: 'Financial sustainability',
    sourcesOfFinancing: 'Sources of financing',
    marketToEconomicConversion: 'Economic analysis/ Conversion of market prices into economic prices',
    economicEffectsIndicators: 'Economic effects indicators',
    socialBenefitsQuantification: 'Social benefits quantification',
    otherIndirectEffects: 'Other indirect effects',
    estimatedCostsDiscounting: 'Discounting of estimated costs and benefits',
    organisationalStructureProposal: 'Methodology of project team establishment/ Organisational structure proposal',
    teamRolesDefinition: 'Team roles definition',
    requiredQualifications: 'Required qualifications',
    monitoringMethod: 'Method of monitoring and evaluation of results',
    implementationCapacities: 'Information on implementation capacities and partner selection',
    projectActivities: 'Project activities',
    budgetActivities: 'Budget activities',
    budgetSummary: 'Budget summary',
    ineligiblePublicFunds: 'Ineligible expenses - public funds',
    ineligiblePrivateFunds: 'Ineligible expenses - private funds',
    budgetSourcesOfFinancing: 'Sources of funding',
    netIncomeEstimationMethod: 'Estimated net income',
    genderEqualityInfluence: 'Horizontal principles/ promotion of gender equality',
    nonDiscriminationPrinciples: 'Promotion of the principle of non-discrimination',
    infrastructureAccessibility: 'Accessibility to infrastructure/ Public transport',
    communicationAccessibility: 'Information and communication accessibility',
    reasonableAdjustment: 'Reasonable adjustment and universal design',
    publicServices: 'Accessibility of other content and services open or intended for the public',
    additionalActivitiesHandicap: 'Additional activities',
    greenPublicProcurement: 'Sustainable development/ Green public procurement',
    climateConfirmation: 'Climate confirmation',
    noSignificantHarmContribution: 'Contribution to the "do no significant harm" principle',
    additionalActivitiesGreen: 'Additional activities',
    euAndUn: 'EU Charter and UN Convention',
    printMedia: 'Information and visibility / Print media',
    electronicMediaPage: 'Electronic media',
    internet: 'Internet',
    seminarsConferences: 'Seminars, conferences, workshops, etc.',
    promotionalMaterial: 'Promotional material',
    newCommunicationForms: 'Proposed new/innovative forms of communication with the public',
    signOrPoster: 'A sign/poster placed at the project location',
    informationBoard: 'Permanent/information board',
    visibilityMarks: 'Visibility marks on project documentation',
    equipmentVisibilityMarks: 'Visibility marks on equipment',
    nonRefundableFunds: 'Non refundable funds',
    yesNo: 'Yes/ No',
    targetGroup: 'Target group',
    positiveInfluence: 'Positive influence',
    neutralInfluence: 'Neutral influence',
    impactExplanation: 'Impact explanation',
    applicant: 'Applicant',
    applicantCostVAT: 'Is VAT on the applicant\'s costs incurred within the project recoverable through regular business?',
    addressNumber: 'Address & street number',
    regionCity: 'Region/city',
    phoneNumber: 'Phone number',
    email: 'E-mail',
    selectApplicant: 'Select applicant',
    firstName: 'First name',
    lastName: 'Last name',
    ownerFunction: 'Function',
    vision: 'Vision',
    mission: 'Mission',
    managementStructure: 'Management structure',
    materialAssets: 'Tangible fixed assets (EUR)',
    nonMaterialAssets: 'Long-term intangible assets (EUR)',
    shorttermMaterialAssets: 'Short-term tangible assets (EUR)',
    shorttermNonMaterialAssets: 'Short-term intangible assets (EUR)',
    unemployedPersonnelNum: 'Number of persons undergoing professional training without employment',
    employeesNum: 'Number of employees',
    volunteersNum: 'Number of volunteers',
    volunteerWorkingHours: 'Volunteer work hours',
    shareCapital: 'Share capital (EUR)',
    businessSubject: 'Subject of business',
    companyDevelopmentPath: 'Development path of the company',
    companyLongtermGoal: 'Dugoročni cilj poduzeća',
    businessAssets: 'Poslovna sredstva',
    sourcesOfFunds: 'Source of funds',
    businessPhysicalVolume: 'Physical volume of business',
    market: 'Market',
    operationsFinancialResults: 'Financial results of operations',
    developmentPlan: 'Development plan with the role of the project in that plan',
    ownershipStructure: 'Ownership structure (ownership shares, i.e. rights of the founder and/or owner)',
    embitda: 'Earnings before interest, taxes and depreciation - EBITDA (EUR)',
    financialIndicators: 'Financial indicators: total expenditure (EUR)',
    businessIncome: 'Operating income (EUR)',
    hasSmallValueAid: 'Has the applicant received support of low value in the current year and in the previous two years?',
    smallValueAid: 'Small aid amount (EUR)',
    hasApplicationStateAid: 'Has the applicant, apart from this application, submitted another application for the award of state aid or aid of small value for costs that are identical to the costs specified in the project description?',
    applicationStateAidValue: 'The amount of the second application for the award of state aid or aid of small value for costs that are identical to the costs specified in the project description (EUR)',
    hasGottenStateAid: 'Has the applicant received state support in the current year and in the previous two years for costs that are identical to the costs stated in the project description?',
    receivedStateAidValue: 'The amount of state aid or low-value aid for costs that are identical to the costs indicated in the project description (EUR)',
    projectsList: 'Projects list',
    projectName: 'Project name',
    stateDonationsIncome: 'Income from donations from the state budget (EUR)',
    regionalDonationsIncome: 'Income from donations from the JLP(R)S (regional) self-government budget (EUR)',
    internationalOrganizationsIncome: 'Income from foreign governments and international organizations (EUR)',
    companiesIncome: 'Income from companies and other legal entities (EUR)',
    householdsIncome: 'Income from citizens and households (EUR)',
    affiliatedIncome: 'Income from affiliated and non-profit organizations (EUR)',
    membershipIncome: 'Income from membership fees (EUR)',
    euFondsIncome: 'Income from EU funds (EUR)',
    economicActivityIncome: 'Income from economic activity (EUR)',
    otherIncome: 'Other income (EUR)',
    nonProfitSector: 'Private non profit sector',
    profitSector: 'Private profit sector',
    implementationStartDate: 'Implementation start date (if the implementation start date is known)',
    implementationDuration: 'Duration of implementation (months)',
    activityName: 'Activity name',
    activityDescription: 'Activity description',
    activityStart: 'Start of activity implementation (implementation month)',
    activityEnd: 'Completion of activity implementation (month of implementation)',
    durationOfImplementation: 'Duration of implementation (months)',
    selectDate: 'Select date',
    expenseType: 'Expense type',
    numOfUnits: 'Number of units',
    unitAmount: 'Unit amount (EUR)',
    totalAMount: 'Total amount (EUR)',
    costBearer: 'Cost bearer',
    actualCost: 'Actual cost',
    SVJT: 'Standard unit cost size',
    lumpSum: 'Lump sum',
    totalCosts: 'Total costs',
    totalEligibleCosts: 'Total eligible costs',
    totalIneligibleCosts: 'Total ineligible costs',
    supportIntensity: 'Support intensity',
    notRelevantIncome: 'The project does not generate income or the income generated is not relevant',
    uniformPercentageRate: 'Application of a uniform percentage rate of net income',
    discountNet: 'Calculation of discounted net income from the project',
    estimateNotPossible: 'It is not possible to estimate in advance',
    estimatedNetIncome: 'Amount of estimated net income',
    fundamentalRightsComplianceEU: 'During the implementation of the project, the applicant will ensure respect for fundamental rights and compliance with the Charter of Fundamental Rights of the European Union',
    conventionComplianceUN: 'During the implementation of the project, the applicant will ensure compliance with the United Nations Convention on the Rights of Persons with Disabilities (UNCRPD) in accordance with Council Decision 2010/48/EC1',
    filterPartners: 'Filter by partners',
    financingCategory: 'Financing category',
    fixedState: 'Type of cost in the financing system using a fixed rate',
    definedLimits: 'Mark the cost with respect to the defined limits',
    unitDefinition: 'Definition of unit',
    resultDefinition: 'Definition of the end result',
    userFunds: 'User funds',
    publicFunds: 'Public funds',
    stateBudgetFunds: 'Public funds',
    localFunds: 'Funds of local and regional self-government',
    otherPublicFunds: 'Other public funds',
    privateFunds: 'Private funds',
  },
  'hrv': {
    shortDescription: 'Sažetak projekta',
    longDescription: 'Opis projekta',
    purposeAndJustification: 'Svrha i opravdanost',
    socioEconomicFramework: 'Socio-ekonomski okvir',
    socioEconomicMainCharacteristics: 'Glavne karakteristike socio-ekonomskog okruženja',
    socioEconomicProjectPurpose: 'Definiranje svrhe projekta i njegov utjecaj na društveno-gospodarsko okruženje',
    nationalFrameworksCompliance: 'Usuglašenost s EU i nacionalnim okvirima',
    institutionalAndLegalFramework: 'Institucionalni i pravni okvir',
    applicantGeneralInformation: 'Opći podaci o prijavitelju',
    contactInfo: 'Kontakt podatci',
    responsiblePersonInfo: 'Podatci o odgovornoj osobi',
    financialCapacities: 'Financijski kapaciteti',
    partners: 'Partneri',
    currentStateDescription: 'Identifikacija projekta/ Opis trenutačnog stanja',
    futureTrendsAssessment: 'Procjena budućih trendova',
    indirectAndNetworkEffects: 'Neizravni i mrežni učinci projekta',
    endUsers: 'Krajnji korisnici',
    targetGroups: 'Ciljne skupine',
    otherOptionsAnalysis: 'Analiza izvedivosti i opcija/ Identifikacija i analiza ostalih mogućnosti',
    bestOptionDefining: 'Definiranje i obrazloženje najbolje mogućnosti',
    projectResultsJustification: 'Obraloženje rezultata projekta',
    demandAnalysis: 'Analiza potražnje',
    locationAndAccessibility: 'Tehnička analiza/ Lokacija i pristupačnost',
    currentSituationDescription: 'Kratak opis postojećeg stanja',
    constructionConcept: 'Građevinsko-arhitektonski koncept',
    equipmentCosts: 'Troškovi izgradnje i/ili opreme',
    infrastructureFunctions: 'Površine i funkcije infrastrukture',
    environmentalImpactAssessment: 'Procjena utjecaja na okoliš',
    technicalDocumentation: 'Dozvole i tehnička dokumentacija',
    humanResourcesManagement: 'Upravljanje ljudskim potencijalima',
    financesAndAccounting: 'Financije i računovodstvo',
    propertyManagement: 'Upravljanje imovinom',
    logistics: 'Logistika',
    marketing: 'Marketing',
    purchases: 'Nabava',
    sales: 'Prodaja',
    strategicPlanning: 'Strateško planiranje',
    riskManagement: 'Analiza rizika',
    otherProcesses: 'Ostali procesi',
    resultsSustainability: 'Održivost rezultata nakon završetka projekta',
    financialAnalysisAssumptions: 'Financijska analiza/ prepostavke financijske analize',
    investmentAmount: 'Visina investicije/ materijalni i nematerijalni troškovi',
    operatingIncomeAndExpenses: 'Operativni prihodi i rashodi',
    financialInvestmentReturn: 'Financijski povrat investicije',
    financialCapitalReturn: 'Financijski povrat kapitala',
    financialSustainability: 'Financijska održivost',
    sourcesOfFinancing: 'Izvori financiranja',
    marketToEconomicConversion: 'Ekonomska analiza/ Konverzija tržišnih u ekonomske cijene',
    economicEffectsIndicators: 'Indikatori ekonomskih učinaka',
    socialBenefitsQuantification: 'Kvantifikacija društvenih koristi',
    otherIndirectEffects: 'Ostali neizravni učinci',
    estimatedCostsDiscounting: 'Diskontiranje procijenjenih troškova i koristi',
    organisationalStructureProposal: 'Metodologija uspostave projektnog tima/ Prijedlog organizacijske strukture',
    teamRolesDefinition: 'Definicija uloga u timu',
    requiredQualifications: 'Opis potrebnih kvalifikacija',
    monitoringMethod: 'Način praćenja i vrednovanja rezultata',
    implementationCapacities: 'Informacije o provedbenim kapacitetima i odabiru partnera',
    projectActivities: 'Aktivnosti projekta',
    budgetActivities: 'Aktivnosti proračuna',
    budgetSummary: 'Sažetak proračuna',
    ineligiblePublicFunds: 'Neprihvatljivi troškovi - javna sredstva',
    ineligiblePrivateFunds: 'Neprihvatljivi troškovi - privatna sredstva',
    budgetSourcesOfFinancing: 'Izvori financiranja',
    netIncomeEstimationMethod: 'Proecijenjeni neto prihod',
    genderEqualityInfluence: 'Horizontalna načela/ promicanje ravnopravnosti spolova',
    nonDiscriminationPrinciples: 'Promicanje načela nediskriminacije',
    infrastructureAccessibility: 'Pristupačnost infrastrukturi/ Javnom prijevozu',
    communicationAccessibility: 'Informacijsko-komunikacijska pristupačnost',
    reasonableAdjustment: 'Razumna prilagodba i univerzalni dizajn',
    publicServices: 'Pristupačnost ostalih sadržaja i usluga otvorenih ili namijenjenih javnosti',
    additionalActivitiesHandicap: 'Dodatne aktivnosti',
    greenPublicProcurement: 'Održivi razvoj/ Zelena javna nabava',
    climateConfirmation: 'Klimatsko potvrđivanje',
    noSignificantHarmContribution: 'Doprinos načelu „ne čini bitnu štetu“',
    additionalActivitiesGreen: 'Dodatne aktivnosti',
    euAndUn: 'EU povelja i UN konvencija',
    printMedia: 'Informiranje i vidljivsot/ Tiskani mediji',
    electronicMedia: 'Elektronički mediji',
    internet: 'Internet',
    seminarsConferences: 'Seminari, konferencije, radionice itd.',
    promotionalMaterial: 'Promotivni materijal',
    newCommunicationForms: 'Predloženi novi/inovativni oblici komunikacije s javnošću',
    signOrPoster: 'Znakovlje/plakat postavljen na lokaciji projekta',
    informationBoard: 'Trajna/informacijska ploča',
    visibilityMarks: 'Oznake vidljivosti na projektnoj dokumentaciji',
    equipmentVisibilityMarks: 'Oznake vidljivosti na opremi',
    nonRefundableFunds: 'Nepovratna sredstva',
    yesNo: 'Da/ Ne',
    targetGroup: 'Ciljna skupina',
    positiveInfluence: 'Pozitivan utjecaj',
    neutralInfluence: 'Neutralan utjecaj',
    impactExplanation: 'Obrazloženje utjecaja',
    applicant: 'Prijavitelj',
    applicantCostVAT: 'PDV na troškove prijavitelja koji nastaju u okviru projekta je povrativ kroz redovno poslovanje?',
    addressNumber: 'Ulica i kucni broj',
    regionCity: 'Općina/grad',
    phoneNumber: 'Broj telefona',
    email: 'E-pošta',
    selectApplicant: 'Odaberi prijavitelja',
    firstName: 'Ime',
    lastName: 'Prezime',
    ownerFunction: 'Funkcija',
    vision: 'Vizija',
    mission: 'Misija',
    managementStructure: 'Upravljačka struktura',
    materialAssets: 'Dugotrajna materijalna imovina (EUR)',
    nonMaterialAssets: 'Dugotrajna nematerijalna imovina (EUR)',
    shorttermMaterialAssets: 'Kratkotrajna materijalna imovina (EUR)',
    shorttermNonMaterialAssets: 'Kratkotrajna nematerijalna imovina (EUR)',
    unemployedPersonnelNum: 'Broj osoba na stručnom osposobljavanju bez zasnivanja radnog odnosa',
    employeesNum: 'Broj zaposlenih',
    volunteersNum: 'Broj volontera',
    volunteerWorkingHours: 'Broj sati volonterskog rada',
    shareCapital: 'Temeljni kapital (EUR)',
    businessSubject: 'Predmet poslovanja',
    companyDevelopmentPath: 'Razvojni put poduzeća',
    companyLongtermGoal: 'Dugoročni cilj poduzeća',
    businessAssets: 'Poslovna sredstva',
    sourcesOfFunds: 'Izvori sredstava',
    businessPhysicalVolume: 'Fizički obujam poslovanja',
    market: 'Tržište',
    operationsFinancialResults: 'Financijski rezultati poslovanja',
    developmentPlan: 'Plan razvoja s ulogom projekta u tom planu',
    ownershipStructure: 'Vlasnička struktura (vlasnički udjeli, odnosno prava osnivača i/ili vlasnika)',
    embitda: 'Dobiti prije kamata, poreza i amortizacije - EBITDA (EUR)',
    financialIndicators: 'Financijski pokazatelji: ukupni izdaci (EUR)',
    businessIncome: 'Poslovni prihodi (EUR)',
    hasSmallValueAid: 'Je li prijavitelj primio potporu male vrijednosti u tekućoj godini i u prethodne dvije godine?',
    smallValueAid: 'Iznos potpore male vrijednosti (EUR)',
    hasApplicationStateAid: 'Je li prijavitelj, osim ove prijave, podnio drugu prijavu za dodjelu državne potpore ili potpore male vrijednosti za troškove koji su istovjetni troškovima navedenima u opisu projekta?',
    applicationStateAidValue: 'Iznos druge prijave za dodjelu državne potpore ili potpore male vrijednosti za troškove koji su istovjetni troškovima navedenima u opisu projekta (EUR)',
    hasGottenStateAid: 'Je li prijavitelj primio državnu potporu u tekućoj godini i u prethodne dvije godine za troškove koji su istovjetni troškovima navedenima u opisu projekta?',
    receivedStateAidValue: 'Iznos državne potpore ili potpore male vrijednosti za troškove koji su istovjetni troškovima navedenima u opisu projekta (EUR)',
    projectsList: 'Lista projekata',
    projectName: 'Naziv projekta',
    stateDonationsIncome: 'Prihodi od donacija iz državnog proračuna (EUR)',
    regionalDonationsIncome: 'Prihodi od donacija iz proračuna JLP(R)S (regionalne) samouprave (EUR)',
    internationalOrganizationsIncome: 'Prihodi od inozemnih vlada i međunarodnih organizacija (EUR)',
    companiesIncome: 'Prihodi od trgovačkih društva i ostalih pravnih osoba (EUR)',
    householdsIncome: 'Prihodi od građana i kućanstava (EUR)',
    affiliatedIncome: 'Prihodi od povezanih i neprofitnih organizacija (EUR)',
    membershipIncome: 'Prihodi od članarina (EUR)',
    euFondsIncome: 'Prihodi iz EU fondova (EUR)',
    economicActivityIncome: 'Prihodi od obavljanja gospodarske djelatnosti (EUR)',
    otherIncome: 'Ostali prihodi (EUR)',
    nonProfitSector: 'Privatni neprofitni sektor',
    profitSector: 'Privatni profitni sektor',
    implementationStartDate: 'Datum početka provedbe (ako je poznat datum početka provedbe)',
    implementationDuration: 'Trajanje provedbe (mjeseci)',
    activityName: 'Naziv aktivnosti',
    activityDescription: 'Opis aktivnosti',
    activityStart: 'Početak provedbe aktivnosti (mjesec provedbe)',
    activityEnd: 'Završetak provedbe aktivnosti (mjesec provedbe)',
    durationOfImplementation: 'Trajanje provedbe (mjeseci)',
    selectDate: 'Odaberite datum',
    expenseType: 'Vrsta troška',
    numOfUnits: 'Broj jedinica',
    unitAmount: 'Iznos jedinice (EUR)',
    totalAmount: 'Iznos ukupno (EUR)',
    costBearer: 'Nositelj troška',
    actualCost: 'Stvarni trošak',
    SVJT: 'Standardna veličina jediničnog troška',
    lumpSum: 'Paušalni iznos',
    totalCosts: 'Ukupni troškovi',
    totalEligibleCosts: 'Ukupni prihvatljivi troškovi',
    totalIneligibleCosts: 'Ukupni neprihvatljivi troškovi',
    supportIntensity: 'Intezitet potpore',
    notRelevantIncome: 'Projekt ne generira prihod ili generirani prihod nije relevantan',
    uniformPercentageRate: 'Primjena jedinstvene stopa postotka neto prihoda',
    discountNet: 'Izračun diskontnih neto prihoda od projekta',
    estimateNotPossible: 'Nije moguće unaprijed procijeniti',
    estimatedNetIncome: 'Iznos procijenjenog neto prihoda',
    fundamentalRightsComplianceEU: 'Prijavitelj će tijekom provedbe projekta osigurati poštivanje temeljnih prava i usklađenost s Poveljom Europske unije o temeljnim pravima',
    conventionComplianceUN: 'Prijavitelj će tijekom provedbe projekta osigurati poštivanje Konvencije Ujedinjenih naroda o pravima osoba s invaliditetom (UNCRPD) u skladu s Odlukom Vijeća 2010/48/EZ1',
    filterPartners: 'Filtriraj po partneru',
    financingCategory: 'Kategorija financiranja',
    fixedState: 'Vrsta troška u sustavu financiranja primjenom fiksne stope',
    definedLimits: 'Oznaka troška s obzirom na  definirana ograničenja',
    unitDefinition: 'Definicija jedinice',
    resultDefinition: 'Definicija krajnjeg rezultata',
    userFunds: 'Sredstva korisnika',
    publicFunds: 'Javna sredstva',
    stateBudgetFunds: 'Sredstva državnog proračuna',
    localFunds: 'Sredstva lokalne i područne samouprave',
    otherPublicFunds: 'Ostala javna sredstva',
    privateFunds: 'Privatna sredstva',
  }
};
